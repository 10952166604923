@import './_style-overwrite';

.color {
	color: var(--vert);;
}
.bg-1 {
	background: var(--col_bg1);
}
.bg-2 {
	background: var(--col_bg2);
}
.bg-3{
	background-color: var(--col_bg3);
}
.bg-4{
	background-color: var(--col_bg4);
	h2,h4,h6{
		background-color: white;
		color: var(--col3);
	}
	h3,h5,.credit{
		color: var(--col3);
	}
	.card{
		color: var(--coltxt);
	}
}
.bg-col1{
	background-color: var(--col1);
}
.bg-col2{
	background-color: var(--col2);
}
.bg-col3{
	background-color: var(--col3);
}
.bg-col4{
	background-color: var(--col4);
}
.vert{
	color: var(--vert);;
}
.couleur-1{
	color: var(--col1)!important;
}
.couleur-2{
	color: var(--col2)!important;
}
.couleur-3{
	color: var(--col3) !important;
}
.couleur-4{
	color: var(--col4)!important;
}
.bg-whtite{
	background-color: white;
	color: var(--coltxt);
	h2,h4,h6{
		background-color: var(--col1);
		color: white;
	}
	.title h2{
		background-color: transparent;
		color: var(--col1);
	}
	h3,
	h5,
	.credit,
	.figure-caption{
		color:white;
	}
}
.bg-col1,
.bg-col2,
.bg-col3,
.bg-col4{
	color: white;
	h2,h4,h6{
		background-color: white;
		color: var(--col1);
	}
	h3,
	h5,
	.credit,
	.figure-caption{
		color:white;
	}
	.card{
		color: var(--coltxt);
	}
}
.bg-col2{
	background-color: var(--col2);
	h2,h4,h6{
		color: var(--col2);
	}
}
.bg-col3{
	h2,h4,h6{
		color: var(--col3);
	}
}
.bg-col4{
	h2,h4,h6{
		color: var(--col4);
	}
}
.bordbleu{
	border: 2px solid var(--col2);
}

.navbar-light .navbar-nav .liendon .nav-link {
	color: var(--col4);
	&:hover{
		color: var(--col_p4);
	}
}
.big{
	font-size: 2rem;
	font-weight: bold;
}
.bigger{
	font-size: 1.2em;
	font-weight: bold;
}
.smaller{
	font-size: 0.8em;
}
.chapo{
	font-weight: bold;
	font-size: 1.1em;
}
.credit{
	text-align: right;
	font-size: 0.8rem;
}
span.credit{
	display: block;
}
.pb{
	padding-bottom: 3rem;
}
.figure{
	.figure-img {
		margin-bottom: 0;
	}	
	.blue-caption{
		background-color: var(--bleu);
		padding: 0 0.5em;
		margin-bottom: 1em;
	}	
}
.ti-icon{
	vertical-align: middle;
}
tfoot{
	font-weight: bold;
}

@media (min-width: 768px) {
	.figure{
		position: relative;
		.figure-img {
			margin-bottom: .5rem;
		}		
		.blue-caption{
			background-color: var(--bleu);
			margin-top: -2rem;
			margin-bottom: 0;
			width: auto;
			display: inline-block;
			position: absolute;
			padding: 0 1.5em 0 0.5em;
			right: 0;
		}	
	}
}

.carousel{
	figcaption {
		color: var(--col1);
		text-align: left;
		font-size: smaller;

		position: absolute;
		right: 15%;
		bottom: 30px;
		left: 15%;
		z-index: 1000;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	ol.carousel-indicators{
		position: relative;
		margin: 2rem 0 0;
	}
}
.card.float-lg-right{
	margin-bottom: 1em;
}
/* Large devices*/
@media (min-width: 992px) {
	.card.float-lg-right{
		margin-left: 1em;
	}
}

.flipped {
    transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
}
.btn{
	background-color: var(--bleu);
	border-color: var(--bleu);
	color: #fff;
	&:hover {
		color: darken(var(--bleu), 10%);
		background-color: lighten(var(--bleu), 20%);
	}
}

.btn-success {
	background-color: var(--vert);;
	border-color: var(--vert);;

	&:hover {
		background-color: var(--vert);;
		border-color: var(--vert);;
	}
}
.btn-warning{
	background-color: var(--col4);
	border-color: var(--col4);
}

#soutenir .btn-warning{
	font-weight: bold;
}

.bg-one,
.navigation,
.counters-item{
	background-color: white;
}

.programme{
	.modal-dialog {
		max-width: 700px;
	}	
	.modal-content{
		.close{
			text-align: right;
		}
		.modal-header{
			display: block;
			border: none;
		}
		.modal-footer{
			border: none;
		}		
	}
	thead{
		font-weight: bold;
		td{
			vertical-align: middle;
		}
	}
	td.nobreak{
		white-space: nowrap;
	}
}
@media (min-width: 576px)
.programme{
	.modal-dialog {
	    max-width: 90%;
	}
}
.centrer{
	text-align: center;
	.aligner {
		text-align: left;
		margin: 1.5em auto 0;
		width: auto;
		display: inline-block;
	}
}
.table.lignesinterieur{
	tbody{
		tr:first-child td {
    		border: none;
		}
	}
	tbody,
	tfoot{
		tr td:first-child  {
			border-right: 1px solid #dee2e6;
		}
		
	}
}

.counter{
	.titre{
		width: 100%;
		text-align: center;
	}
}
.counters-item{
	.rond {
	  position:relative;	
	  border-radius: 50%;
	  -moz-border-radius: 50%;
	  -webkit-border-radius: 50%;
	  background-color: var(--vert);;
	  color: white;
	  border: 1px solid var(--vert);;
	  width: 7em;
	  height: 7em;
	  margin:0 auto 1em;
	  span{
		  position:absolute;
		  top:50%; left:50%;
		  transform: translate(-50%, -50%);
		  margin:0;
	  }
	  &.trans{
		background-color: var(--col7);
		color: var(--vert);;
	  }
	}
	strong{
		color: var(--vert);;
	}
	&.bleu 	strong{
		color: var(--bleu);
	}
}
.bleu{
	color: var(--bleu);
	.rond {
	  background-color: var(--bleu);
	  border-color: var(--bleu);
	  &.trans{
		background-color: var(--col8);
		color: var(--bleu);
			&.blanc{
				background-color: white;
			}
		}	  
	}
	h3{
		color: var(--bleu);
	}
}


#mot-intro{
	.card-body{
		padding: 0.25rem;
		color: white;
		background-color: var(--bleu);
		font-size: small;
		h5{
			color: white;
			margin: 0;
			font-size: 1em;
			font-weight: bold;

		}
	}
}

.container-fluid {
	.img-fluid.maxwidth{
		max-width: 300px;	
	}
}
#provinces_projets{
	.card{
		background-color: transparent;
		border: none;
		.card-img-overlay{
			padding: 0;
		}
		a{
			color: var(--col1);
		}
		.card-text{
			color: white;
			font-size: 0.9em;

		}
	}	
	.modal-dialog {
		max-width: unset;
		width: fit-content;
		.modal-header {
			border-bottom: none;
		}
		.modal-footer {
			justify-content: space-between;
		}	
	}
}


.bull{
	color: var(--bleu);
	font-size: 2.5rem;
	line-height: 0.1;
	font-weight: bold;
	vertical-align: middle;
}